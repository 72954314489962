//@flow
export const 通貨 = Object.freeze({
  JPY: "JPY",
  USD: "USD",
  AED: "AED",
})

export const 通貨Config = Object.freeze({
  //title:表記、prefix:前接頭辞、suffix:後接頭辞、local:toStirng用、roundIn:input際に制限小数、roundOutN:normal時期用表示制限小数、roundOutM:more小数桁数表示制限
  JPY: { title: "円", prefix: "¥", suffix: "円", local: "ja-JP", roundIn: 0, roundOutN: 0, roundOutM: 1 },
  USD: { title: "USD", prefix: "$", suffix: "USD", local: "en-US", roundIn: 2, roundOutN: 2, roundOutM: 3 },
  AED: { title: "AED", prefix: "AED", suffix: "AED", local: "en-US", roundIn: 2, roundOutN: 2, roundOutM: 3 },
})

export type 通貨型 = $Values<typeof 通貨>

export const get通貨Prefix = (currency: 通貨型): string => {
  switch (currency) {
    case 通貨.JPY: return 通貨Config.JPY.prefix
    case 通貨.USD: return 通貨Config.USD.prefix
    case 通貨.AED: return 通貨Config.AED.prefix
    default: return 通貨Config.JPY.prefix
  }
}

export const get通貨Suffix = (currency: 通貨型): string => {
  switch (currency) {
    case 通貨.JPY: return 通貨Config.JPY.suffix
    case 通貨.USD: return 通貨Config.USD.suffix
    case 通貨.AED: return 通貨Config.AED.suffix
    default: return 通貨Config.JPY.suffix
  }
}

export const get通貨Local = (currency: 通貨型): string => {
  switch (currency) {
    case 通貨.JPY: return 通貨Config.JPY.local
    case 通貨.USD: return 通貨Config.USD.local
    case 通貨.AED: return 通貨Config.AED.local
    default: return 通貨Config.JPY.local
  }
}

export const get通貨RoundIn = (currency: 通貨型): number => {
  switch (currency) {
    case 通貨.JPY: return 通貨Config.JPY.roundIn
    case 通貨.USD: return 通貨Config.USD.roundIn
    case 通貨.AED: return 通貨Config.AED.roundIn
    default: return 通貨Config.JPY.roundIn
  }
}

export const get通貨RoundOutN = (currency: 通貨型): number => {
  switch (currency) {
    case 通貨.JPY: return 通貨Config.JPY.roundOutN
    case 通貨.USD: return 通貨Config.USD.roundOutN
    case 通貨.AED: return 通貨Config.AED.roundOutN
    default: return 通貨Config.JPY.roundOutN
  }
}

export const get通貨RoundOutM = (currency: 通貨型): number => {
  switch (currency) {
    case 通貨.JPY: return 通貨Config.JPY.roundOutM
    case 通貨.USD: return 通貨Config.USD.roundOutM
    case 通貨.AED: return 通貨Config.AED.roundOutM
    default: return 通貨Config.JPY.roundOutM
  }
}

// サーバ側の共通通貨値から数値型のローカル通貨値に変更
export const toLocal通貨Number = (v: any, currency: 通貨型): number => {
  const numValue = parseFloat(String(v))
  if(isNaN(numValue)) {
    return 0
  }
  const 小数点以下有効桁数 = get通貨RoundIn(currency)
  return numValue / Math.pow(10, 小数点以下有効桁数)
}