// @flow

import { USER_ACTION_TYPE, USER_STATUS } from "../constants/userConstants"
import { API_TOKEN } from "../constants/Localstorage"
import { UserState, UserStateZero, UserAction, UserPayload, Account } from "../interfaces/User"

const initialState = UserStateZero

export function userReducer(state: UserState = initialState, action: UserAction): UserState {
  switch (action.type) {
    case USER_ACTION_TYPE.REQUEST: return { ...state, status: USER_STATUS.IS_INITIAL_STATE }
    case USER_ACTION_TYPE.RECEIVE_SUCCESS: return receiveSuccess(state, action)
    case USER_ACTION_TYPE.RECEIVE_FAILED: return receiveFailed(state, action)
    case USER_ACTION_TYPE.RECEIVE_SERVER_ERROR: return serverError(state, action)
    case USER_ACTION_TYPE.LOGOUT: return logout(state)
    default: return state
  }
}

const receiveSuccess = (state: UserState, action: UserAction) => {
  if (action.payload != null) {
    const payload: UserPayload = action.payload
    if (payload.account != null) {
      const account: Account = payload.account
      if (account != null) {
        console.log("reducer --- type: " + action.type)
        console.log("reducer --- action.payload.account.account: ")
        console.log(account)
        console.log("reducer --- action.payload.account: " + account.account)
        localStorage.setItem(API_TOKEN, account.account)
      }
    }

    return {
      ...state,
      status: USER_STATUS.IS_LOGGED_IN,
      user: action.payload,
    }
  }
  return state
}

const receiveFailed = (state: UserState, action: UserAction) => {
  if (action.payload != null) {
    const payload: UserPayload = action.payload
    if (payload.account != null) {
      const account: Account = payload.account
      if (account != null) {
        console.log("reducer --- type: " + action.type)
        console.log("reducer --- action.payload.account.account: ")
        console.log(account);
        console.log("reducer --- action.payload.account: " + account.account)
        localStorage.setItem(API_TOKEN, account.account)
      }
    }

    return {
      ...state,
      status: USER_STATUS.IS_FAILED,
      user: action.payload,
    }
  }
  return state
}

const serverError = (state: UserState, action: UserAction) => {
  if (action.payload != null) {
    const payload: UserPayload = action.payload
    if (payload.account != null) {
      const account: Account = payload.account
      if (account != null) {
        console.log("reducer --- type: " + action.type)
        console.log("reducer --- action.payload.account.account: ")
        console.log(account);
        console.log("reducer --- action.payload.account: " + account.account)
        localStorage.setItem(API_TOKEN, account.account)
      }
    }

    return {
      ...state,
      status: USER_STATUS.IS_SERVER_ERROR,
      user: action.payload,
    }
  }
  return state
}


const logout = (state: UserState) => {
  localStorage.removeItem(API_TOKEN)
  return {
    ...state,
    status: USER_STATUS.IS_INITIAL_STATE,
  }
}
