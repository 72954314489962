//@flow

import type { EigyoCurrentResponse } from "../network/api/eigyoCurrent"
import { 通貨型 } from "./Currency"

export const API_TOKEN = 'API_TOKEN'
const EIGYO_CURRENT_RESPONSE = 'EIGYO_CURRENT_RESPONSE'
const TENPO_TIMEZONE_OFFSET_HOUR = 'TENPO_TIMEZONE_OFFSET_HOUR'
const TENPO_EIGYO_SHIME = 'TENPO_EIGYO_SHIME'
const TENPO_CURRENCY = 'TENPO_CURRENCY'

export const localStorageSetEigyoSummary = (eigyoDto: EigyoCurrentResponse) => {
  localStorage.setItem(EIGYO_CURRENT_RESPONSE, JSON.stringify(eigyoDto))
  localStorage.setItem(TENPO_TIMEZONE_OFFSET_HOUR, JSON.stringify(eigyoDto.tenpo.timezoneOffset))
  localStorage.setItem(TENPO_EIGYO_SHIME, JSON.stringify(eigyoDto.tenpo.eigyoShime))
  localStorage.setItem(TENPO_CURRENCY, eigyoDto.tenpo.currency)
}
export const localStorageSetCurrency = (currency: 通貨型)  => {
  localStorage.setItem(TENPO_CURRENCY, currency)
}

export const localStorageGetEigyoSummary = (): EigyoCurrentResponse => {
  return JSON.parse(localStorage.getItem(EIGYO_CURRENT_RESPONSE))
}

export const localStorageGetTimezoneOffset = (): number => {
  return parseInt(localStorage.getItem(TENPO_TIMEZONE_OFFSET_HOUR))
}

export const localStorageGetEigyoShime = (): number => {
  return parseInt(localStorage.getItem(TENPO_EIGYO_SHIME))
}

export const localStorageGetCurrency = (): 通貨型 => {
  return localStorage.getItem(TENPO_CURRENCY)
}
