//@flow

export const USER_ACTION_TYPE = {
  REQUEST : 'REQUEST',
  RECEIVE_SUCCESS : 'RECEIVE_SUCCESS',
  RECEIVE_FAILED : 'RECEIVE_FAILED',
  RECEIVE_SERVER_ERROR : 'RECEIVE_SERVER_ERROR',
  COMMON_RECEIVE_HANDLED_ERROR : 'COMMON_RECEIVE_HANDLED_ERROR',
  LOGOUT : 'LOGOUT'
}

export const USER_STATUS = {
  IS_LOGGED_IN : "IS_LOGGED_IN",
  IS_FAILED : "IS_FAILED",
  IS_SERVER_ERROR : "IS_SERVER_ERROR",
  IS_INITIAL_STATE : "IS_INITIAL_STATE"
}