// @flow

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import './App.scss';
import { connect } from "react-redux";

import { API_TOKEN } from './constants/Localstorage';
import { Loading } from './contents/Loading';

//const Auth = (props) => ( props.user.status === IS_LOGGED_IN ? props.children : <Redirect to={'/login'}/>)
const alertedAuth = () => {
  try {
    localStorage.getItem(API_TOKEN)
    //alert(localStorage.getItem(API_TOKEN))
    alert('ログアウトしました')
  } catch (e) {
    alert('localStorageが利用できません')
  }
  return <Redirect to={'/login'} />
}
const Auth = (props) => (localStorage.getItem(API_TOKEN) ? props.children : alertedAuth())
const ConnectedAuth = connect(state => ({ user: state.userReducer }))(Auth);

const loading = () => <Loading />

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});

const Register = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});

const OrderScanCode = Loadable({
  loader: () => import('./contents/GroupEntry/OrderScanCode'),
  loading
});
const ScanCode = Loadable({
  loader: () => import('./contents/GroupEntry/ScanCode'),
  loading
});
const ScanCodeTop = Loadable({
  loader: () => import('./contents/GroupEntry/ScanCodeTop'),
  loading
});
const groupEntry = Loadable({
  loader: () => import('./contents/GroupEntry/groupEntry'),
  loading
});
const ScanCodePinvestigation = Loadable({
  loader: () => import('./contents/GroupEntry/ScanCodePinvestigation'),
  loading
});

class App extends Component<{}> {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/register" name="Register Page" component={Register} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route exact path="/orderScanCode" name="OrderScanCode" component={OrderScanCode} />
          <Route exact path="/scanCode" name="ScanCode" component={ScanCode} />
          <Route exact path="/scanCodeTop" name="ScanCodeTop" component={ScanCodeTop} />
          <Route exact path="/groupEntry/:id/:type" name="groupEntry" component={groupEntry} />
          <Route exact path="/scanCodePinvestigation/:id/:type" name="ScanCodePinvestigation" component={ScanCodePinvestigation} />
          <ConnectedAuth>
            <Route path="/" name="Home" component={DefaultLayout} />
          </ConnectedAuth>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default connect(
  state => ({ user: state.userReducer })
)(App);
