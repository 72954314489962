//@flow

import { USER_STATUS } from "../constants/userConstants"

export interface Tenpo {
  actorInTime: number, //timestamp
  actorOutTime: number, //timestamp
  close: number, //timestamp
  code: string,
  contractId: string,
  coursType: string,
  deleted: boolean,
  extensionDetermineTiming: 0,
  id: string,
  name: string,
  open: number, //timestamp
  serviceTimeRounding: number,
  setserviceBaseDuration: number,
  setserviceExtensionDuration: number,
  shortName: string,
  workTimeRounding: number
}
export const TenpoZero = {
  actorInTime: 0, //timestamp
  actorOutTime: 0, //timestamp
  close: 0, //timestamp
  code: "",
  contractId: "",
  coursType: "",
  deleted: false,
  extensionDetermineTiming: 0,
  id: "",
  name: "",
  open: 0, //timestamp
  serviceTimeRounding: 0,
  setserviceBaseDuration: 0,
  setserviceExtensionDuration: 0,
  shortName: "",
  workTimeRounding: 0
}

export interface Account {
  account: string,
  admin: boolean,
  createdAt: number, //timestamp
  enabled: boolean,
  id: string,
  name: string,
  updatedAt: number //timestamp
}
export const AccountZero = {
  account: "",
  admin: false,
  createdAt: 0, //timestamp
  enabled: false,
  id: "",
  name: "",
  updatedAt: 0 //timestamp
}

export interface UserPayload {
  result: string,
  cause?: string,
  account?: Account,
  tenpoList?: Array<Tenpo>
}
export const UserPayloadZero = ({
  result: "",
  cause: "",
  account: (AccountZero: Account),
  tenpoList: ([TenpoZero]: Array<Tenpo>)
} : UserPayload)

export interface UserState {
  status: string,
  user: UserPayload
}

export interface UserAction {
  type: string,
  payload?: UserPayload
}

export const UserStateZero = {
  status: USER_STATUS.IS_INITIAL_STATE,
  user: UserPayloadZero
}
